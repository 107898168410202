<template>
  <v-container class="pa-0">
    <v-card flat>
      <v-card-title>
        <div class="text-h4 text-right" color="red">
          {{ display || "0.00" }}
        </div>
        <v-spacer></v-spacer>
        <div class="text-h4 text-right" @click="opencart">
          {{ this.formatCurrency(cartItemTotal) || "0.00" }}
        </div>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row no-gutters style="height: 100%" ref="container">
          <!-- Teclado POS -->
          <v-col cols="12">
            <v-row no-gutters class="flex-grow-1">
              <v-col cols="12">
                <v-row no-gutters style="height: 100%">
                  <v-col cols="9">
                    <v-row no-gutters style="height: 100%">
                      <!-- Teclas numéricas -->
                      <v-col
                        v-for="n in 9"
                        :key="n"
                        cols="4"
                        class="pa-1"
                        style="height: 25%"
                      >
                        <v-btn
                          block
                          x-large
                          class="pos-btn"
                          @click="handleNumericInput(n)"
                          color="grey lighten-2"
                        >
                          <span class="text-h6">{{ n }}</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="4" class="pa-1" style="height: 25%">
                        <v-btn
                          block
                          x-large
                          class="pos-btn"
                          @click="handleNumericInput(0)"
                          color="grey lighten-2"
                        >
                          <span class="text-h6">0</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="4" class="pa-1" style="height: 25%">
                        <v-btn
                          block
                          x-large
                          class="pos-btn"
                          @click="handleNumericInput('.')"
                          color="grey lighten-1"
                        >
                          <span class="text-h6">.</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="4" class="pa-1" style="height: 25%">
                        <v-btn
                          block
                          x-large
                          class="pos-btn"
                          @click="handleMultiply"
                          color="blue lighten-3"
                        >
                          <span class="text-h6">X</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="3">
                    <v-row no-gutters style="height: 100%">
                      <v-col cols="12" class="pa-1" style="height: 25%">
                        <v-btn
                          block
                          x-large
                          class="pos-btn"
                          @click="handleLst"
                          color="info dark-2"
                        >
                          <span class="text-subtitle-2"> </span>
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pa-1" style="height: 25%">
                        <v-btn
                          block
                          x-large
                          class="pos-btn"
                          @click="handleClear"
                          color="red lighten-2"
                        >
                          <span class="text-subtitle-2">C</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pa-1" style="height: 25%">
                        <v-btn
                          block
                          x-large
                          dark
                          class="pos-btn"
                          @click="handlePLU"
                          color="green lighten-1"
                        >
                          <span class="text-subtitle-2">PLU</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="pa-1" style="height: 25%">
                        <v-btn
                          block
                          x-large
                          class="pos-btn"
                          @click="handleEnter"
                          color="green darken-1"
                        >
                          <span class="text-subtitle-2 white--text">ENTER</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- <v-card outlined class="d-flex flex-column keyboard-col" flat> -->
            <!-- Botones de departamento -->
            <v-row no-gutters class="mt-2">
              <v-col
                v-for="dept in departments"
                :key="dept.id"
                cols="3"
                class="pa-1"
                style="height: auto"
              >
                <v-btn
                  block
                  x-large
                  class="text-wrap pos-btn"
                  @click="handleDepartment(dept.id)"
                  :color="dept.color"
                >
                  <div class="dept-content">
                    <div class="dept-id">{{ formatDeptId(dept.id) }}</div>
                    <span class="text-caption text-wrap">{{ dept.name }}</span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
            <!-- </v-card> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
export default {
  name: "POSSystem",
  components: {},
  props: {
    products: {
      type: Array,
      required: false,
      default: () => [],
    },

    cartItemTotal: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      productslist_dialog: false,
      panel: false,
      display: "",
      toPay: "0.00",
      qty: 1,
      mode: "PRICE", // PRICE, QTY, PLU
      tempDeptNumber: "",
      priceBeforeCode: null,
    };
  },
  computed: {
    departments() {
      var categories = [
        "ARTICULOS DE ACERO",
        "ARTICULOS DE BEBE",
        "CACHARRERIA",
        "CALZADO",
        "CARTERAS Y BOLSOS",
        "DULCES",
        "ESCOLAR",
        "FERRETERIA",
        "HIGIENE PERSONAL",
        "HOGAR Y DECORACION",
        "JUGUETERIA",
        "MASCOTAS",
        "PIÑATERIA",
        "PRENDAS DE VESTIR",
        "LOZA Y CRISTALERIA",
        "RELOJERIA",
        "SALUD Y BELLEZA",
        "TECNOLOGIA",
        "VARIEDADES",
        "ACCESORIOS",
      ];
      if (this.products.length > 0) {
        categories = [...new Set(this.products.map((item) => item.category))];
        console.log(categories);
      }
      const colors = [
        "cyan lighten-3",
        "pink lighten-3",
        "light-green lighten-3",
        "amber lighten-3",
        "cyan lighten-2",
        "pink lighten-2",
        "light-green lighten-2",
        "amber lighten-2",
        "cyan lighten-1",
        "pink lighten-1",
        "light-green lighten-1",
        "amber lighten-1",
      ];
      return categories.map((category, index) => ({
        id: index + 1,
        name: category,
        color: colors[index % colors.length],
      }));
    },
  },
  mounted() {
    //this.$refs.container.focus();
    window.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    opencart() {
      this.$emit("opencart");
    },
    close() {
      this.$emit("close", false);
    },
    handleLst() {
      this.$emit("products_dialog");
    },

    handleKeyDown(event) {
      const elementType = this.getElementType(event.target);
      const ignoredTypes = ["input", "select", "textarea"];

      if (ignoredTypes.includes(elementType)) {
        return;
      }

      const key = event.key;
      console.log("Key pressed:", event);

      if (/^[0-9.]$/.test(key)) {
        this.handleNumericInput(key);
      } else if (key === "Enter") {
        // this.handleEnter();
        if (event.shiftKey) {
          // Manejar Shift+Enter
          this.handleShiftEnter();
        } else {
          // Enter normal
          this.handleEnter();
        }
      } else if (key === "Backspace") {
        this.handleClear();
      } else if (key === "*" || key.toLowerCase() === "x") {
        this.handleMultiply();
      } else if (key === "+") {
        this.handlePlus();
      }
    },

    handleShiftEnter() {
      this.$emit("fastPayment", true);
    },

    handlePlus() {
      if (this.display && !isNaN(parseFloat(this.display))) {
        this.priceBeforeCode = parseFloat(this.display);
        this.display = "";
        this.mode = "CODE"; // Nuevo modo para esperar código
      }
    },

    handleDeptMode() {
      if (this.display && !isNaN(parseFloat(this.display))) {
        this.priceBeforeDept = parseFloat(this.display); // Guardamos el precio
        this.mode = "DEPT";
        this.tempDeptNumber = "";
        this.display = "DEP-";
      }
    },
    handleClear() {
      this.display = "";
      this.qty = 1;
      this.mode = "PRICE";
      this.priceBeforeCode = null;
    },
    getElementType(target) {
      // Si target es una cadena (como "input#input-229"), extraemos el tipo
      if (typeof target === "string") {
        return target.split("#")[0];
      }
      // Si target es un elemento DOM, usamos su tagName
      if (target instanceof Element) {
        return target.tagName.toLowerCase();
      }
      // Si no podemos determinar el tipo, retornamos una cadena vacía
      return "";
    },
    handleNumericInput(value) {
      this.display += value.toString();
    },
    handleMultiply() {
      if (this.display) {
        this.qty = parseFloat(this.display) || 1;
        this.display = "";
        this.mode = "QTY";
      }
    },

    handlePLU() {
      if (this.display) {
        const pluCode = this.display;
        this.get_product(pluCode);
      }
    },
    async get_product(pluCode) {
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: [
          {
            field: "id_pos",
            operator: "=",
            value: window.store.store_id.substring(0, 6),
          },
          {
            field: "ean",
            operator: "=",
            value: pluCode,
          },
        ],
      };
      // console.log(qry);
      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_catalog",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });

      var result = await promise;
      //  console.log(result);
      if (result.length > 0) {
        result[0].cantidad = this.qty;
        this.$emit("product-selected", result[0]);
        this.handleClear();
      } else {
        this.$emit("product-not-found", pluCode);
      }
    },

    handleDepartment(deptId) {
      if (this.display) {
        const price = parseFloat(this.display);
        const dept = this.departments.find((d) => d.id === deptId);
        const product = this.createProductByPrice(price, dept);
        this.emitProduct(product);
        this.handleClear();
      }
    },
    handleEnter() {
      if (this.mode === "CODE" && this.display && this.priceBeforeCode !== null) {
        const code = this.display.trim();

        // Si es código de 2 dígitos, es departamento
        if (/^\d{2}$/.test(code)) {
          const deptId = parseInt(code);
          const dept = this.departments.find((d) => d.id === deptId);
          if (dept) {
            const product = this.createProductByPrice(this.priceBeforeCode, dept);
            this.emitProduct(product);
          } else {
            this.$emit("product-not-found", code);
          }
        } else {
          // Si no es código de 2 dígitos, buscar PLU
          this.get_product(code);
        }
        this.handleClear();
      } else {
        // Mantener el comportamiento original del Enter para otros casos
        if (this.mode === "PRICE" || this.mode === "QTY") {
          if (this.display) {
            const price = parseFloat(this.display);
            const dept = this.departments[0];
            const product = this.createProductByPrice(price, dept);
            this.emitProduct(product);
            this.handleClear();
          }
        }
      }
    },
    createProductByPrice(price, dept) {
      return {
        id_pos: window.store.store_id,
        sku: dept.name + price,
        ean: dept.name + price,
        categoria: dept.name,
        grupo: "",
        marca: dept.name,
        refe: dept.name + "-" + price,
        color: price,
        talla: "",
        cantidad: this.qty,
        costo: "0",
        precio: price,
        precioB: "0",
        precioC: "0",
        descuento: "0",
        store_name: this.$store.state.seller.PartyName,
        category: dept.name,
        groupName: "",
        brand: dept.name,
        colorName: "",
      };
    },
    emitProduct(product) {
      this.$emit("product-selected", {
        ...product,
        qty: this.qty,
        tItem: product.price * this.qty,
      });
    },
    parseNumber(value) {
      if (typeof value === "number") {
        return value;
      }
      if (typeof value === "string") {
        // Eliminar cualquier carácter que no sea dígito o punto
        const cleanedValue = value.replace(/[^\d.]/g, "");
        return parseFloat(cleanedValue);
      }
      return NaN;
    },
    formatCurrency(value) {
      try {
        const numValue = this.parseNumber(value);
        if (isNaN(numValue)) {
          console.warn("Invalid input for formatCurrency:", value);
          return "Invalid input";
        }
        // Usar Intl.NumberFormat para un formateo más robusto
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(numValue);
      } catch (error) {
        console.error("Error in formatCurrency:", error);
        return "Error formatting currency";
      }
    },
    formatDeptId(id) {
      // Formatear el ID como "01", "02", etc.
      return id.toString().padStart(2, "0");
    },
  },
  watch: {
    panel: function (e) {
      if (e) {
        this.$emit("panel");
      }
    },
  },
};
</script>

<style scoped>
.text-wrap {
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-word;
  text-align: center;
  max-height: 3em; /* Reducido de 3.6em para dar espacio al ID */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Reducido a 2 líneas */
  -webkit-box-orient: vertical;
}
.pos-btn {
  text-transform: none;
  height: 60px !important;
  transition: all 0.1s ease;
}

.pos-btn:active {
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) inset;
}

.display-card {
  background-color: #1e1e1e !important; /* Dark background */
  border-radius: 4px;
}

@media (max-width: 600px) {
  .pos-btn {
    font-size: 1rem;
    white-space: normal;
    max-width: 80px;
  }
}
.pos-container {
  display: flex;
  flex-direction: column;
}

.keyboard-col {
  height: calc(100vh - 224px) !important;
}

@media (min-width: 960px) {
  .pos-container {
    flex-direction: row;
  }

  .keyboard-col {
    height: calc(100vh - 250px) !important;
  }

  .pos-keyboard {
    height: 100%;
  }
}

/* Estilos adicionales para el nuevo display estilo Casio */
.display-card.casio-style {
  background-color: #00ff00 !important; /* Verde brillante como en la imagen */
  color: #000000 !important;
  font-family: "Courier New", monospace;
}
.display-content {
  display: flex;
  flex-direction: column;
}
.header {
  text-align: left;
  font-weight: bold;

  color: greenyellow;
}

.dept-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dept-id {
  font-size: 0.75rem;
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.1);
  padding: 0px 4px;
  border-radius: 4px;
  margin-bottom: 2px;
}
</style>
